<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title
        style="display: flex; flex-direction: row; justify-content: space-between; font-family: 'Poppins-SemiBold'"
      >
        Report
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="app-bold-font font-20">
        Why are you reporting this?
      </v-card-text>
      <v-card-text v-if="!category">
        <div
          class="d-flex align-center hover-item px-4 w-100 rounded-lg font-18 dark-font-color py-6 justify-between"
          :class="{
            'selected-item-color': item.title == category,
          }"
          v-for="item in categories"
          :key="item.title"
          @click="onSelectCategory(item)"
        >
          {{ item.title }}
          <v-icon color="#1e1e1e">mdi-arrow-right</v-icon>
        </div>
      </v-card-text>
      <v-card-text
        class="d-flex justify-center flex-column mt-3"
        v-if="category"
      >
        <v-radio-group v-model="reason" @change="onChange">
          <v-radio
            :value="option.title"
            v-for="option in reasonList"
            :key="option.key"
          >
            <template v-slot:label>
              <div
                class="d-flex hover-item pa-2 w-100 rounded-lg flex-column justify-center"
                :class="{
                  'selected-item-color': reason == option.title,
                }"
              >
                <div class="app-bold-font font-18 mb-2">
                  {{ option.title }}
                </div>
                <div>{{ option.description }}</div>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-text v-if="message">
        <v-alert dense type="error" v-text="message" v-if="message" />
      </v-card-text>
      <v-card-actions class="pb-6 mt-3 d-flex justify-end" v-if="category">
        <v-btn color="primary" outlined width="150" @click="onBack">Back</v-btn>
        <v-btn
          color="primary"
          @click="onSubmit"
          class="white--text"
          width="150"
          :loading="loading"
          :disabled="!reason"
          >Submit</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    type: String,
    _id: String,
    onClose: {
      type: Function,
    },
    onReported: Function,
  },
  data() {
    return {
      message: null,
      loading: false,
      category: null,
      reason: null,
      categories: [
        {
          title: "Suspicious, spam, or fake",
          children: [
            {
              title: "Misinformation",
              description:
                "Spreading false or misleading information as if it were factual",
            },
            {
              title: "Fraud or scam",
              description:
                "Deceiving others to obtain money or access private information",
            },
            {
              title: "Spam",
              description:
                "Sharing irrelevant or repeated content to boost visibility or for monetary gain",
            },
            {
              title: "Fake account",
              description: "Inaccurate or misleading representation",
            },
          ],
        },
        {
          title: "Harassment or hateful speech",
          children: [
            {
              title: "Bullying or trolling",
              description:
                "Attacking or intimidating others, or deliberately and repeatedly disrupting conversations",
            },
            {
              title: "Sexual harassment",
              description:
                "Unwanted romantic advances, requests for sexual favors, or unwelcome sexual remarks",
            },
            {
              title: "Hateful or abusive speech",
              description: "Hateful, degrading, or inflammatory speech",
            },
            {
              title: "Spam",
              description:
                "Sharing irrelevant or repeated content to boost visibility or for monetary gain",
            },
          ],
        },
        {
          title: "Violence or physical harm",
          children: [
            {
              title: "Inciting violence or is a threat",
              description:
                "Encouraging violent acts or threatening physical harm",
            },
            {
              title: "Self-harm",
              description: "Suicidal remarks or threatening to harm oneself",
            },
            {
              title: "Shocking or gory",
              description: "Shocking or graphic content",
            },
            {
              title: "Terrorism or act of extreme violence",
              description:
                "Depicting or encouraging terrorist acts or severe harm",
            },
          ],
        },
        {
          title: "Adult content",
          children: [
            {
              title: "Nudity or sexual content",
              description:
                "Nudity, sexual scenes or language, or sex trafficking",
            },
            {
              title: "Shocking or gory",
              description: "Shocking or graphic content",
            },
            {
              title: "Sexual harassment",
              description:
                "Unwanted romantic advances, requests for sexual favors, or unwelcome sexual remarks",
            },
          ],
        },
        {
          title: "Intellectual property infringement or defamation",
          children: [
            {
              title: "Copyright infringement",
              description:
                "Using your copyrighted work without your permission",
            },
            {
              title: "Trademark infringement",
              description: "Using your trademark without your permission",
            },
            {
              title: "Defamation",
              description:
                "Sharing falsehoods about you that could harm your reputation",
            },
          ],
        },
      ],
      reasonList: [],
    };
  },
  methods: {
    ...mapActions("event", {
      reportEvent: "report",
      reportComment: "reportComment",
    }),
    onChange() {
      this.message = null;
      console.log(this.reason);
    },
    onSubmit() {
      if (this.type == "Event") {
        this.loading = true;

        this.reportEvent({
          _id: this._id,
          reason: this.category + "/" + this.reason,
        })
          .then(() => {
            this.loading = false;
            this.onReported();
          })
          .catch((error) => {
            this.loading = false;
            if (error.response) {
              this.message = error.response.data.message;
            } else {
              this.message = error;
            }
          });
      } else if (this.type == "Comment") {
        this.loading = true;

        this.reportComment({
          _id: this._id,
          reason: this.category + "/" + this.reason,
        })
          .then(() => {
            this.loading = false;
            this.onReported();
          })
          .catch((error) => {
            this.loading = false;
            if (error.response) {
              this.message = error.response.data.message;
            } else {
              this.message = error;
            }
          });
      }
    },
    onBack() {
      this.reason = null;
      this.category = null;
    },
    onSelectCategory(item) {
      this.category = item.title;
      this.reasonList = item.children;
    },
  },
};
</script>
<style scoped>
.selected {
  background: #eef3f8;
}
</style>
