<template>
  <div>
    <div v-if="comment && !getReported && !reported">
      <div class="d-flex">
        <editable-avatar
          :image="comment.poster_image"
          :editable="false"
          :size="$vuetify.breakpoint.xs ? 30 : 40"
          class="mr-3"
        />
        <div
          class="d-flex flex-column pa-3"
          style="background-color: #f2f2f2; color: black; width: calc(100% - 52px); border-radius: 0 10px 10px 10px"
        >
          <div
            style="font-weight: 500; padding-bottom: 10px; justify-content: space-between"
            class="d-flex"
          >
            <span
              v-text="
                comment.poster_name
                  ? comment.poster_name
                  : comment.first_name + ' ' + comment.last_name
              "
              class="url-button"
              @click="goToProfile(comment.poster, comment.poster_type)"
              :class="$vuetify.breakpoint.xs ? 'font-12' : 'font-14'"
            />
            <div>
              <span
                style="font-size:10px; color:darkgray"
                v-text="getTimeAgo(comment.createdAt)"
              />
              <v-menu offset-y rounded="lg" left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon class="ml-1" v-bind="attrs" v-on="on" small
                    ><v-icon>mdi-dots-horizontal</v-icon></v-btn
                  >
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in getDropMenu(comment)"
                    v-on:change="onSelectedMenu(item, comment)"
                    :key="index"
                    link
                  >
                    <v-list-item-title class="mr-3">
                      <v-icon v-text="item.icon" class="mr-3" />
                      <span v-text="item.title" />
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <span
            v-text="comment.text"
            :class="
              ($vuetify.breakpoint.xs ? 'font-12 ' : 'font-14 ') +
                (comment.file ? 'mb-3' : 'mb-0')
            "
            class="text-block"
            v-if="!editingComment"
          />
          <div v-else>
            <v-textarea
              v-model="editedText"
              placeholder="Add a comment..."
              rows="1"
              auto-grow
              dense
              flat
              solo
              hide-details
              background-color="#f2f2f2"
            />
            <div class="mt-2 mb-2">
              <v-btn
                rounded
                color="primary"
                class="mr-2 mb-2"
                :disabled="comment.text == editedText || !editedText"
                @click="onUpdateComment"
                :loading="isUpdatingComment"
                :x-small="$vuetify.breakpoint.xs"
                >Save changes</v-btn
              >
              <v-btn
                rounded
                @click="onCancelUpdateComment"
                :x-small="$vuetify.breakpoint.xs"
                class="mb-2"
                >Cancel</v-btn
              >
            </div>
          </div>
          <div v-if="comment.file">
            <img
              :src="comment.file"
              contain
              style="max-height: 240px; object-fit: contain; max-width: 100%;"
            />
          </div>
        </div>
      </div>
      <div class="d-flex align-center ml-10 mt-2">
        <comment-like-board
          :onLike="onLike"
          :liked="likeData ? likeData.liked : null"
        />
        <comment-reaction-details
          :data="likeData"
          :_id="comment._id"
          v-if="likeData"
        />
      </div>
      <confirm-delete-dialog
        :deleteDialog="deleteDialog"
        :onClose="onCloseConfirmDelete"
        :onConfirm="onConfirmDelete"
        text="This action can not be undone. Are you sure do you want to delete the comment?"
        :loading="loading"
      />
      <report-event-dialog
        type="Comment"
        :_id="comment._id"
        :dialog="reportDialog"
        :onClose="onCloseReportDialog"
        :onReported="onReported"
        v-if="reportDialog && comment && comment._id"
      />
    </div>
    <div v-if="reported">Comment has been reported.</div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import CommentLikeBoard from "./CommentLikeBoard.vue";
import EditableAvatar from "@/components/EditableAvatar.vue";
import ConfirmDeleteDialog from "@/components/common/ConfirmDeleteDialog.vue";
import CommentReactionDetails from "./CommentReactionDetails.vue";
import ReportEventDialog from "./ReportEventDialog.vue";
export default {
  components: {
    CommentLikeBoard,
    EditableAvatar,
    ConfirmDeleteDialog,
    CommentReactionDetails,
    ReportEventDialog,
  },
  props: {
    comment: Object,
    onUpdatedComment: Function,
    onDeletedComment: Function,
  },
  data() {
    return {
      likeData: null,
      editingComment: false,
      editedText: "",
      isUpdatingComment: false,
      dropmenus: [{ title: "Report", icon: "mdi-flag" }],
      deleteDialog: false,
      reportDialog: false,
      loading: false,
      reported: false,
    };
  },
  methods: {
    ...mapActions("event", {
      deleteComment: "deleteComment",
      editComment: "editComment",
      getCommentLikeCount: "getCommentLikeCount",
      like: "commentLike",
    }),
    getDropMenu(item) {
      if (item.poster == this.profile._id) {
        return [
          { title: "Edit", icon: "mdi-pencil" },
          { title: "Delete", icon: "mdi-delete" },
        ];
      } else return [{ title: "Report", icon: "mdi-flag" }];
    },
    getLiked() {
      this.loading = true;
      this.getCommentLikeCount({ _id: this.comment._id })
        .then((data) => {
          this.loading = false;
          this.likeData = data;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            console.log(error.response.data.message);
          }
        });
    },
    onLike(type) {
      console.log(type);
      this.loading = false;
      this.like({ _id: this.comment._id, type })
        .then((data) => {
          this.loading = false;
          this.likeData = { ...this.likeData, ...data };
        })
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    getTimeAgo(oldTimeStr) {
      const oldTime = Date.parse(oldTimeStr);
      const time = new Date();
      const different = (time.getTime() - oldTime) / 1000;
      const month = (different / 3600 / 24 / 30) | 0;
      const days = (different / 3600 / 24) % 30 | 0;
      const hours = (different / 3600) % 24 | 0;
      const mins = (different / 60) % 60 | 0;
      if (month == 1) return `1 month`;
      if (month) return `${month} months`;
      if (days == 1) return `1 day`;
      if (days) return `${days} days`;
      if (hours == 1) return `1 hours`;
      if (hours) return `${hours} hours`;
      if (mins == 1) return `1 min`;
      if (mins) return `${mins} min`;
      return `less than 1 min`;
    },
    onSelectedMenu(item) {
      if (item.title == "Delete") {
        this.deleteDialog = true;
      } else if (item.title == "Edit") {
        this.editedText = this.comment.text;
        this.editingComment = true;
      } else if (item.title == "Report") {
        this.reportDialog = true;
      }
    },
    onCancelUpdateComment() {
      this.editingComment = false;
    },
    onUpdateComment() {
      this.isUpdatingComment = true;
      this.editComment({ text: this.editedText, _id: this.comment._id })
        .then((data) => {
          this.isUpdatingComment = false;
          this.editingComment = false;
          this.selectedFile = null;
          this.selectedImage = "";
          this.text = "";
          data.poster = this.profile._id;
          data.poster_type = this.profileType;
          data.poster_image = this.profile.thumbnail
            ? this.profile.thumbnail
            : this.profile.image;
          data.poster_name = this.profile.name
            ? this.profile.name
            : this.profile.first_name + " " + this.profile.last_name;
          this.onUpdatedComment(data);
        })
        .catch((error) => {
          this.isUpdatingComment = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    onConfirmDelete() {
      this.loading = true;
      this.deleteComment({ _id: this.comment._id })
        .then((data) => {
          this.loading = false;
          this.deleteDialog = false;
          this.onDeletedComment(data);
        })
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    onCloseConfirmDelete() {
      this.deleteDialog = false;
    },
    onCloseReportDialog() {
      this.reportDialog = false;
    },
    onReported() {
      this.reportDialog = false;
      this.reported = true;
    },
    goToProfile(poster, type) {
      if (type == "Agency") {
        this.$router.push({
          name: "agency-profile-details",
          params: { id: poster },
        });
      } else if (type == "Volunteer") {
        this.$router.push({
          name: "volunteer-profile-details",
          params: { id: poster },
        });
      }
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      profileType: "type",
    }),
    getReported() {
      if (
        !this.comment ||
        !this.comment.reported ||
        !this.comment.reported.length
      )
        return null;
      for (let i = 0; i < this.comment.reported.length; i++) {
        if (this.comment.reported[i]._id == this.profile._id)
          return this.comment.reported[i].reason;
      }
      return null;
    },
  },
  mounted() {
    this.getLiked();
  },
};
</script>
