<template>
  <v-menu
    top
    offset-y
    transition="fab-transition"
    origin="bottom left"
    open-on-hover
    :close-delay="1000"
    v-model="board"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        text
        :color="getColor"
        @click="onClickMainButton"
        @mousedown="openMenu"
        small
      >
        {{ liked ? liked : "Like" }}
      </v-btn>
    </template>
    <v-card style="background: white; " class="rounded-pill mb-3">
      <v-tooltip top v-for="button in buttons" :key="button.type">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            :small="$vuetify.breakpoint.mdAndUp"
            :x-small="$vuetify.breakpoint.smAndDown"
            :color="button.color"
            :class="$vuetify.breakpoint.smAndDown ? 'ma-1' : 'ma-3'"
            v-bind="attrs"
            v-on="on"
            @click="onLike(button.type)"
          >
            <div>
              <v-img
                :src="
                  require(`@/assets/svg/reactions/circle/${button.type.toLowerCase()}.svg`)
                "
                :width="$vuetify.breakpoint.mdAndUp ? 25 : 30"
                :height="$vuetify.breakpoint.mdAndUp ? 25 : 30"
                contain
              />
            </div>
          </v-btn>
        </template>
        {{ button.type }}
      </v-tooltip>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  props: {
    onLike: Function,
    liked: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      buttons: [
        {
          type: "Like",
          color: "#1485BD",
        },
        {
          type: "Celebrate",
          color: "#6dae4f",
        },
        {
          type: "Support",
          color: "#B094D1",
        },
        {
          type: "Love",
          color: "#DF704D",
        },
        {
          type: "Insightful",
          color: "#FEBB5C",
        },
        {
          type: "Curious",
          color: "#DEA2DB",
        },
      ],
      board: false,
    };
  },
  methods: {
    onClickMainButton() {
      this.board = false;
      this.onLike(this.liked ? this.liked : "Like");
    },
    openMenu() {
      console.log(this.board);
      if (!this.board) this.board = true;
    },
  },
  computed: {
    getColor() {
      if (!this.liked) return "grey";
      var color = "grey";
      this.buttons.map((b) => {
        if (b.type == this.liked) {
          color = b.color;
        }
      });
      return color;
    },
    getIcon() {
      if (!this.liked) return "empty";
      return this.liked.toLowerCase();
    },
  },
};
</script>
<style scoped>
.v-menu__content {
  box-shadow: none;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
