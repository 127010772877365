<template>
  <div class="d-flex flex-row">
    <div @click="onShowReactionDialog" class="d-flex">
      <div v-for="(item, index) in data.types" :key="index" class="mr-1">
        <v-img
          :src="
            require(`@/assets/svg/reactions/circle/${item.toLowerCase()}.svg`)
          "
          width="21"
          height="21"
          contain
        />
      </div>
    </div>
    <div class="ml-2">
      <span
        class="url-button"
        v-text="data.count ? data.count : 0"
        v-if="data.count"
        @click="onShowReactionDialog"
      />
      <span v-if="data.comments && data.count"> ·</span>
      <span
        class="url-button"
        @click="onComment"
        v-if="data.comments"
        v-text="(data.comments ? data.comments : 0) + ' comments'"
      />
    </div>
    <event-reaction-dialog
      :_id="_id"
      :dialog="reactionDialog"
      :onCloseDialog="onCloseDialog"
      v-if="reactionDialog"
    />
  </div>
</template>
<script>
import EventReactionDialog from "./EventReactionDialog.vue";
export default {
  components: { EventReactionDialog },
  props: {
    data: Object,
    onComment: Function,
    _id: {
      type: String,
    },
  },
  data() {
    return {
      reactionDialog: false,
    };
  },
  methods: {
    onShowReactionDialog() {
      this.reactionDialog = true;
    },
    onCloseDialog() {
      this.reactionDialog = false;
    },
  },
};
</script>
<style scoped></style>
