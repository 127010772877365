<template>
  <div class="w-100">
    <v-card
      v-if="event && !loading && !getReported && (showBlocked || !getBlocked)"
      class="mb-4"
      width="100%"
    >
      <v-menu offset-y rounded="lg" left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            style="position: absolute; right: 15px; top: 10px"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in dropMenus"
            v-on:change="onSelectedMenu(item)"
            :key="index"
            link
          >
            <v-list-item-title class="mr-3">
              <v-icon v-text="item.icon" class="mr-3" />
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-card-title class="d-flex flex-row mr-9" style="align-items:flex-start">
        <editable-avatar
          :image="
            event.poster.thumbnail ? event.poster.thumbnail : event.poster.image
          "
          :editable="false"
          :size="$vuetify.breakpoint.xs ? 40 : 50"
          class="mr-5"
        />
        <div class="d-flex flex-column">
          <span
            v-text="event.poster.name"
            class="url-button"
            :style="$vuetify.breakpoint.xs ? 'font-size: 16px' : ''"
            @click="goToProfile(event.poster._id, event.poster.type)"
          />
          <span
            style="font-size:10px; color:darkgray"
            :style="$vuetify.breakpoint.xs ? 'line-height: 16px' : ''"
            v-text="getTimeAgo(event.createdAt)"
          />
        </div>
      </v-card-title>
      <v-card-text class="pb-0" style="position: relative">
        <span
          class="text-block"
          v-if="text_collapsed"
          v-linkified
          v-text="collapsed_text"
        />
        <span v-else v-text="event.text" class="text-block" v-linkified />
        <span
          class="url-button"
          @click="text_collapsed = false"
          v-if="text_collapsed"
          x-small
          style="position: absolute; right: 18px; bottom: 0; background: white"
        >
          ...see more
        </span>
      </v-card-text>
      <v-card-text
        class="px-0"
        v-observe-visibility="{
          callback: visibilityChanged,
          intersection: {
            threshold: 0.8,
          },
          throttle: 600,
          throttleOptions: {
            leading: 'visible',
          },
        }"
        v-viewer="options"
      >
        <div v-if="event.type == 'image' && event.file">
          <img
            :src="event.file"
            min-height="200px"
            style="min-height: 200px; width: 100%"
            contain
            :data-source="event.file"
            loading="eager"
          />
        </div>
        <!-- <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="#fdbc3a" />
            </v-row>
          </template> -->

        <vue-plyr
          ref="plyr"
          :options="{
            autoplay: false,
            muted: true,
            autopause: true,
          }"
          v-else-if="event.type == 'video' && event.file"
        >
          <video
            controls
            crossorigin
            playsinline
            id="videoElement"
            @canplay="updatePaused"
            @playing="updatePaused"
            @pause="updatePaused"
            @ended="onEnded"
          >
            <source :src="event.file" type="video/mp4" />
          </video>
        </vue-plyr>
      </v-card-text>
      <v-card-text
        class="pt-0"
        v-if="likeData && (likeData.comments || likeData.count)"
      >
        <feed-event-reaction-details
          :data="likeData"
          :onComment="showComments"
          :_id="_id"
        />
      </v-card-text>
      <v-card-text class="pt-0">
        <event-feed-like-board
          :onLike="onLike"
          :liked="likeData ? likeData.liked : null"
        />
        <v-btn text color="grey" @click="editComments">
          <v-icon class="mr-3">mdi-comment-text-outline</v-icon>
          Comment
        </v-btn>
      </v-card-text>
      <v-card-text v-if="commendable" class="d-flex flex-row">
        <feed-event-post-comment
          ref="feedEventComment"
          :_id="_id"
          :onPosted="onPostedComment"
          :total="likeData.comments"
          :commentId="commentId"
        />
      </v-card-text>
      <v-snackbar v-model="snackbar" style="z-index: 10001;">
        {{ errorMessage }}
      </v-snackbar>
      <confirm-delete-dialog
        :deleteDialog="deleteDialog"
        :onClose="onCloseConfirmDelete"
        :onConfirm="onConfirmDelete"
        text="This action can not be undone. Are you sure do you want to delete the event?"
        :loading="loading"
      />
      <edit-event-dialog
        v-if="editDialog"
        :event="{ ...event }"
        :dialog="editDialog"
        :onUpdated="onUpdatedEvent"
        :onCloseDialog="onCloseEditDialog"
      />
      <report-event-dialog
        type="Event"
        :_id="_id"
        :dialog="reportDialog"
        :onClose="onCloseReportDialog"
        :onReported="onReported"
        v-if="reportDialog && _id"
      />
      <block-event-dialog
        :_id="_id"
        :dialog="blockDialog"
        :onClose="onCloseBlockDialog"
        :onBlocked="onBlocked"
        v-if="blockDialog && _id"
      />
    </v-card>
    <v-card v-if="event && getReported && showReport" class="mb-4">
      <v-card-title>
        This event reported by you.<br />
        Reason: {{ getReported }}
      </v-card-title>
    </v-card>
    <v-card v-else-if="errorEvent">
      <v-card-title class="d-flex justify-center py-15">
        Invalid or deleted event
      </v-card-title>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";
import FeedEventPostComment from "./FeedEventPostComment.vue";
import EventFeedLikeBoard from "./EventFeedLikeBoard.vue";
import FeedEventReactionDetails from "./FeedEventReactionDetails.vue";
import ConfirmDeleteDialog from "@/components/common/ConfirmDeleteDialog.vue";
import EditEventDialog from "./EditEventDialog.vue";
import ReportEventDialog from "./ReportEventDialog.vue";
import Viewer from "v-viewer";
import Vue from "vue";
import "viewerjs/dist/viewer.css";
import BlockEventDialog from "./BlockEventDialog.vue";

Vue.use(Viewer, {
  debug: false,
  defaultOptions: {
    zIndex: 99999,
  },
});

export default {
  components: {
    EditableAvatar,
    FeedEventPostComment,
    EventFeedLikeBoard,
    FeedEventReactionDetails,
    ConfirmDeleteDialog,
    EditEventDialog,
    ReportEventDialog,
    BlockEventDialog,
  },
  props: {
    _id: {
      type: String,
    },
    loadedData: {
      type: Function,
    },
    showReport: {
      type: Boolean,
      default: false,
    },
    showBlocked: {
      type: Boolean,
      default: false,
    },
    eventIndex: {
      type: Number,
      default: 0,
    },
    loadMoreEvents: {
      type: Function,
    },
    onDeleted: Function,
    commentId: String,
  },
  data() {
    return {
      loading: false,
      event: null,
      autoPlaying: true,
      videoElement: null,
      paused: null,
      atouched: false,
      errorMessage: null,
      snackbar: false,
      dropMenus: [
        { title: "Copy link to event", icon: "mdi-link-variant" },
        { title: "I don't want to see this", icon: "mdi-eye-off" },
        // { title: "Unfollow", icon: "mdi-close-circle" },
        { title: "Report", icon: "mdi-flag" },
      ],
      commendable: false,
      likeData: null,
      deleteDialog: false,
      editDialog: false,
      reportDialog: false,
      blockDialog: false,
      text_collapsed: true,
      collapsed_text: "",
      errorEvent: false,
      options: {
        toolbar: true,
        scalable: false,
        title: false,
        url: "data-source",
      },
    };
  },
  computed: {
    ...mapGetters({
      globalPlaying: "event/playing",
      profile: "auth/getProfile",
      profileType: "auth/getType",
    }),
    getReported() {
      if (!this.event || !this.event.reported || !this.event.reported.length)
        return null;
      for (let i = 0; i < this.event.reported.length; i++) {
        if (this.event.reported[i]._id == this.profile._id)
          return this.event.reported[i].reason;
      }
      return null;
    },
    getBlocked() {
      if (!this.event || !this.event.blocked || !this.event.blocked.length)
        return null;
      for (let i = 0; i < this.event.blocked.length; i++) {
        if (this.event.blocked[i]._id == this.profile._id)
          return this.event.blocked[i].reason;
      }
      return null;
    },
  },
  methods: {
    ...mapActions("event", {
      getEvent: "getEvent",
      setPlaying: "setPlaying",
      getLikeCount: "getLikeCount",
      like: "like",
      deleteEvent: "deleteEvent",
    }),
    visibilityChanged(isVisible, entry) {
      if (isVisible && entry.intersectionRatio > 0.5) {
        if (this.event.type == "video") {
          if (this.autoPlaying) this.$refs.plyr.player.muted = true;
          this.$refs.plyr.player.play();
          this.autoPlaying = false;
          this.atouched = true;
          this.setPlaying(this._id);
        }
        if (this.eventIndex == 3) {
          this.loadMoreEvents();
        }
      }
    },
    updatePaused(event) {
      this.videoElement = event.target;
      this.paused = event.target.paused;
    },
    onEnded() {
      console.log("ended");
    },
    editComments() {
      this.commendable = true;
      setTimeout(() => {
        this.$refs.feedEventComment.makeFocus();
      }, 200);
    },
    showComments() {
      this.commendable = true;
    },
    onLike(type) {
      this.loading = false;
      this.like({ _id: this._id, type })
        .then((data) => {
          this.loading = false;
          this.likeData = { ...this.likeData, ...data };
        })
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    getLiked() {
      this.loading = true;
      this.getLikeCount({ _id: this._id })
        .then((data) => {
          this.loading = false;
          this.likeData = data;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
      console.log(message);
    },
    onPostedComment() {
      if (this.likeData.comments) this.likeData.comments++;
      else this.likeData = { ...this.likeData, comments: 1 };
    },
    onSelectedMenu(item) {
      if (item.title == "Delete Post") {
        this.showDeleteDialog();
      } else if (item.title == "Edit Post") {
        this.showEditDialog();
      } else if (item.title == "Copy link to event") {
        this.copyToClipboard(
          process.env.VUE_APP_WEB_URL + "/event-details?_id=" + this.event._id
        );
        this.snackbar = true;
        this.errorMessage = "copied share url";
      } else if (item.title == "Report") {
        this.reportDialog = true;
      } else if (item.title == "I don't want to see this") {
        this.blockDialog = true;
      }
    },
    showDeleteDialog() {
      this.deleteDialog = true;
    },
    showEditDialog() {
      this.editDialog = true;
    },
    copyToClipboard(textToCopy) {
      // navigator clipboard api needs a secure context (https)
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
      } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // here the magic happens
          document.execCommand("copy") ? res() : rej();
          textArea.remove();
        });
      }
    },
    onCloseConfirmDelete() {
      this.deleteDialog = false;
    },
    onConfirmDelete() {
      this.loading = true;
      if (this.$refs.plyr) this.$refs.plyr.player.pause();
      setTimeout(() => {
        this.deleteEvent({ _id: this.event._id })
          .then(() => {
            this.loading = false;
            if (this.onDeleted) {
              this.onDeleted(this.event);
            }
          })
          .catch((error) => {
            this.loading = false;
            if (error.response == undefined) {
              console.log(error);
            } else {
              this.showErrorMessage(error.response.data.message);
            }
          });
      }, 200);
    },
    getTimeAgo(oldTimeStr) {
      const oldTime = Date.parse(oldTimeStr);
      const time = new Date();
      const different = (time.getTime() - oldTime) / 1000;
      const month = (different / 3600 / 24 / 30) | 0;
      const days = (different / 3600 / 24) % 30 | 0;
      const hours = (different / 3600) % 24 | 0;
      const mins = (different / 60) % 60 | 0;
      if (month == 1) return `1 month ago`;
      if (month) return `${month} months ago`;
      if (days == 1) return `1 day ago`;
      if (days) return `${days} days ago`;
      if (hours == 1) return `1 hours ago`;
      if (hours) return `${hours} hours ago`;
      if (mins == 1) return `1 min ago`;
      if (mins) return `${mins} min ago`;
      return `less than 1 min ago`;
    },
    getCollapsedText() {
      if (!this.event.text) {
        this.text_collapsed = false;
        return;
      }
      var textArray = this.event.text.split(/\r\n|\r|\n/);
      var text1 = "";
      for (var i = 0; i < textArray.length; i++) {
        if (i >= 3) break;
        text1 += textArray[i] + "\n";
      }
      text1 = text1.trim();
      var text2 = this.event.text.substring(0, 270);
      text2 = text2.trim();
      if (text1.length < text2.length) {
        this.collapsed_text = text1;
      } else {
        this.collapsed_text = text2;
      }
      if (this.collapsed_text.length == this.event.text.length) {
        this.text_collapsed = false;
      } else {
        this.text_collapsed = true;
      }
    },
    onCloseEditDialog() {
      this.editDialog = false;
    },
    onCloseReportDialog() {
      this.reportDialog = false;
    },
    onCloseBlockDialog() {
      this.blockDialog = false;
    },
    onReported() {
      this.reportDialog = false;
      this.getData();
    },
    onBlocked() {
      this.blockedDialog = false;
      this.getData();
    },
    onUpdatedEvent(event) {
      this.editDialog = false;
      this.event = event;
    },
    goToProfile(poster, type) {
      if (type == "Agency") {
        this.$router.push({
          name: "agency-profile-details",
          params: { id: poster },
        });
      } else if (type == "Volunteer") {
        this.$router.push({
          name: "volunteer-profile-details",
          params: { id: poster },
        });
      }
    },
    getData() {
      this.loading = true;
      this.getEvent({ _id: this._id })
        .then((event) => {
          this.loading = false;
          this.event = event;
          this.getCollapsedText();
          if (this.loadedData) this.loadedData(this._id);
          this.getLiked();
          if (this.commentId) this.commendable = true;
          if (
            this.event.poster._id == this.profile._id ||
            this.profileType == "Admin"
          ) {
            this.dropMenus = [
              { title: "Copy link to event", icon: "mdi-link-variant" },
              { title: "Edit Post", icon: "mdi-pencil-outline" },
              { title: "Delete Post", icon: "mdi-trash-can" },
            ];
          } else {
            this.dropMenus = [
              { title: "Copy link to event", icon: "mdi-link-variant" },
              { title: "I don't want to see this", icon: "mdi-eye-off" },
              // { title: "Unfollow", icon: "mdi-close-circle" },
              { title: "Report", icon: "mdi-flag" },
            ];
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
            if (error.response.data.message == "event not found") {
              this.errorEvent = true;
            }
          }
        });
    },
  },
  watch: {
    paused(newValue) {
      if (!this.atouched) {
        this.$refs.plyr.player.muted = false;
      }
      this.atouched = false;
      if (!newValue) {
        if (this.globalPlaying != this._id) {
          this.setPlaying(this._id);
        }
      } else {
        if (this.globalPlaying == this._id) {
          this.setPlaying("");
        }
      }
    },
    globalPlaying(newValue) {
      if (newValue && this._id != newValue) {
        if (this.$refs.plyr) {
          this.$refs.plyr.player.pause();
          this.atouched = true;
        }
      }
    },
  },
  mounted() {
    if (this._id) {
      this.getData();
    }
  },
};
</script>
<style scoped>
div >>> .plyr video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
div >>> .plyr__video-wrapper {
  padding-top: 56.2%;
}
.text-block {
  white-space: pre-line;
  color: black;
  overflow: hidden;
  display: block;
}
</style>
